/* eslint-disable @typescript-eslint/no-this-alias */
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const qcobjects_1 = require("qcobjects");
const qcobjects_sdk_1 = require("qcobjects-sdk");
(0, qcobjects_1.Package)("com.qcobjects.controllers.newsletter", [
    class NewsletterController extends qcobjects_1.Controller {
        constructor({ component, dependencies }) {
            super({ component, dependencies });
            this.serviceClass = "NewsletterService";
            this.formSettings = {
                backRouting: "#",
                loadingRouting: "#",
                nextRouting: "#"
            };
            this.validations = {
                email() {
                    return (0, qcobjects_1.ClassFactory)("FormValidations").getDefault("email");
                }
            };
            if (typeof this.component === "undefined") {
                alert("NewsletterController: component is required");
            }
            this.defaulController = new qcobjects_sdk_1.FormController({ component, dependencies });
            this.defaulController.serviceClass = this.serviceClass;
            this.defaulController.formSettings = this.formSettings;
        }
        done() {
            qcobjects_1.logger.debugEnabled = true;
            const controller = this.defaulController;
            try {
                qcobjects_1.logger.debug("Trying to execute DONE NewsletterController");
                // eslint-disable-next-line no-useless-call
                controller?.done.call(controller);
            }
            catch (e) {
                qcobjects_1.logger.debug(`Unable to execute default behavior ${e}`);
            }
        }
    }
]);
