"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const qcobjects_1 = require("qcobjects");
const qcobjects_sdk_1 = require("qcobjects-sdk");
(0, qcobjects_1.Package)("com.qcobjects.api.client_services", [
    class NewsletterService extends qcobjects_1.JSONService {
        constructor() {
            super();
            this.name = "qcobjects_newsletter";
            this.external = false;
            this.cached = false;
            this.method = "post";
            this.headers = {
                "content-type": "application/json"
            };
            this.basePath = "";
            this.url = "/rest/contactform";
            this.withCredentials = false;
            qcobjects_sdk_1.NotificationComponent
                .info(qcobjects_1.CONFIG.get("notifications", { sendingDataMessage: "Success!" }).sendingDataMessage);
            location.href = "#sending-email-form";
        }
        done(standardResponse) {
            qcobjects_1.logger.debug(standardResponse.service.template);
            const response = JSON.parse(standardResponse.service.template);
            qcobjects_sdk_1.NotificationComponent
                .success(qcobjects_1.CONFIG.get("notifications", { dataSentMessage: "Data sent!" }).dataSentMessage);
            if (typeof response.status !== "undefined" && response.status === "OK") {
                location.href = "#email-form-sent";
            }
            else {
                qcobjects_sdk_1.NotificationComponent
                    .warning(`${qcobjects_1.CONFIG.get("notifications", { dataFailureMessage: "Failure" }).dataFailureMessage}\n${response.status}`);
            }
        }
        fail(failure) {
            console.log(failure);
            qcobjects_sdk_1.NotificationComponent.warning(qcobjects_1.CONFIG.get("notifications", "Success!").dataFailureMessage);
        }
    }
]);
