"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const qcobjects_1 = require("qcobjects");
(0, qcobjects_1.Package)("com.qcobjects.effects.transitions.main", [
    class MainTransitionEffect extends qcobjects_1.TransitionEffect {
        constructor() {
            super(...arguments);
            this.name = "main-transition-effect2";
            this.duration = 385;
            this.effects = ["Fade"];
        }
    }
]);
